
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider"
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { Web3Auth } from "@web3auth/web3auth";

const infuraId = "c7f0ce0d797648b5bef4c4c2e5fbc70a"
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId
    }
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "App",
      infuraId
    }
  },
  web3auth: {
    package: Web3Auth,
    options: {
      infuraId
    }
  }
};
var web3Modal;

export function init() {
  web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: false, // optional
    providerOptions // required
  });
}

export function getAddress() {

  if (typeof window === "undefined")
    return 0

  const savedAddy = window.sessionStorage.getItem('address')

  if (savedAddy != undefined) {
    return savedAddy
  }
  else {
    return 0
  }

}


export async function connectAddress() {

  var ret = getAddress()

  if (ret != 0)
    return ret
  else {
    return requestAccount()
  }
}

export function clearAddress() {
  sessionStorage.clear()
  try {


    web3Modal.clearCachedProvider();
  } catch (e) {
    console.log(e)
  }
  /*
  if(provider.close) {
    await provider.close();

    // If the cached provider is not cleared,
    // WalletConnect will default to the existing session
    // and does not allow to re-scan the QR code with a new wallet.
    // Depending on your use case you may want or want not his behavior.
    web3Modal.clearCachedProvider();
    provider = null;
  }
  */
  //window.location.reload();
}

export async function requestAccount() {
  var account = 0

  try {


    const instance = await web3Modal.connect();

    const provider = new ethers.providers.Web3Provider(instance);

    const accounts = await provider.listAccounts();

    account = accounts[0];

    window.sessionStorage.setItem('address', accounts[0])

    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts) => {
      console.log(accounts);
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainId) => {
      console.log(chainId);
    });

    // Subscribe to provider connection
    provider.on("connect", (info) => {
      console.log(info);
    });

    // Subscribe to provider disconnection
    provider.on("disconnect", (error) => {
      console.log(error);
    });
  } catch (e) {
    console.log(e)
  }
  //const signer = provider.getSigner();
  //console.log(signer)

  /*

  // ❌ Check if Meta Mask Extension exists 
  if (typeof window !== "undefined" && window.ethereum) {
    try {

      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      account = accounts[0];

      window.sessionStorage.setItem('address', accounts[0])

    } catch (error) {
      alert('Error connecting... Please try again');

      window.location.reload();
    }

  } else {
    alert('Web3 provider not detected');
  }
  */


  return account
}