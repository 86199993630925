import React, { useState, useEffect } from "react";
import * as auth from "../helpers/auth";
import './App.css';

const GET_LOGS = `
query GetLogs($user_id: Int){
  awsec_logs(order_by: {id: desc}, where: {user_id: {_eq: $user_id}})  {
    logdesc
    created_at
  }
}
`;

const INSERT_LOG = `
mutation InsertLog($user_id: Int, $logdesc: String) {
  insert_awsec_logs_one(object: {user_id: $user_id, logdesc: $logdesc}) {
    created_at
    logdesc
  }
}

`

const GET_USERS = `
query {
  awsec_users {
    addy
  }
}
`

const GET_USER_ID = `query GetUserId($addy: String) {
  awsec_users(where: {addy: {_eq: $addy}}) {
    addy
    id
  }
}
`


function App() {
  const [locked, setLock] = useState(false) //auth.getLockVerification

  const [addy, setAddy] = useState(auth.getAddress());

  const [logs, setLogs] = useState();
  const [user, setUser] = useState();


  useEffect(() => {
    auth.init()

    if (addy != 0) {
      fetchUser(addy).then((u) => {
        fetchLogs(u)
      })
    }


  }, [])

  async function fetchUser(addy) {
    if (addy != 0) {
      const response = await fetch('https://serve.bloxx.space/v1/graphql', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "x-hasura-admin-secret": "0yessirski"
        },
        body: JSON.stringify({
          query: GET_USER_ID,
          variables: {
            addy: addy
          }
        })
      })

      const ret = await response.json()
      console.log(ret)
      setUser(ret.data.awsec_users[0].id)

      return ret.data.awsec_users[0].id
    }
  }

  async function fetchLogs(user_id) {

    if (typeof user_id != "undefined") {
      const response = await fetch('https://serve.bloxx.space/v1/graphql', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "x-hasura-admin-secret": "0yessirski"
        },
        body: JSON.stringify({
          query: GET_LOGS,
          variables: {
            user_id: user_id
          }
        })
      })

      const ret = await response.json()
      console.log(ret)
      setLogs(ret.data.awsec_logs)
    }
  }

  async function pushLog(state) {
    if (typeof user != "undefined") {
      const response = await fetch('https://serve.bloxx.space/v1/graphql', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "x-hasura-admin-secret": "0yessirski"
        },
        body: JSON.stringify({
          query: INSERT_LOG,
          variables: {
            user_id: user,
            logdesc: state ? "Lock Enabled" : "Lock Disabled"
          }
        })
      })

      const ret = await response.json()
      console.log(ret)
    }
  }


  function disconnectWallet() {
    console.log("depush")
    setAddy(0);
    auth.clearAddress();
  }

  function connectWallet() {
    console.log("push")
    auth.connectAddress().then((addy) => {
      setAddy(addy);
      fetchUser(addy).then((u) => {
        fetchLogs(u)
      })

    });
  }

  function toggleLockState(state) {

    if (addy != 0) {
      setLock(state)
      pushLog(state).then(()=>{
        fetchLogs(user)
      })

    }
  }

  const ReedSwitch = (props) => {

    return (
      <>
        <div style={{ width: 'min-content' }} onClick={props.onClick}>
          {props.locked ? <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 24 24"><path d="M17 9.761v-4.761c0-2.761-2.238-5-5-5-2.763 0-5 2.239-5 5v4.761c-1.827 1.466-3 3.714-3 6.239 0 4.418 3.582 8 8 8s8-3.582 8-8c0-2.525-1.173-4.773-3-6.239zm-8-4.761c0-1.654 1.346-3 3-3s3 1.346 3 3v3.587c-.927-.376-1.938-.587-3-.587s-2.073.211-3 .587v-3.587zm3 17c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zm2-6c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2z" /></svg>
            : <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 24 24"><path d="M12 18c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2zm0-10c-1.062 0-2.073.211-3 .587v-3.587c0-1.654 1.346-3 3-3s3 1.346 3 3v1h2v-1c0-2.761-2.238-5-5-5-2.763 0-5 2.239-5 5v4.761c-1.827 1.466-3 3.714-3 6.239 0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8zm0 14c-3.313 0-6-2.687-6-6s2.687-6 6-6 6 2.687 6 6-2.687 6-6 6z" /></svg>}
        </div>
      </>

    )
  }

  return (
    <div className="App">

      <div style={{ marginTop: "25vh" }}>
        <center>
          {addy != 0 ? "Connected" : "Disconnected ..."}<br />
          {addy != 0 ? addy : ""}<br />
          <button
            style={{
              background: addy != 0 ? "hsl(340deg 100% 32%)" : "hsl(100deg 100% 32%)",
            }}
            class="pushable"
            id="submit"
            onClick={() => (addy != 0 ? disconnectWallet() : connectWallet())}
          >
            <span
              style={{
                backgroundColor: addy != 0 ? "#cc264a" : "#0F0",
                color: addy != 0 ? "#FFF" : "#000",
              }}
              class="front"
            >
              {addy != 0 ? "Disconnect" : "Connect"}
            </span>
          </button><br />

          <br />
          <ReedSwitch locked={locked} onClick={() => toggleLockState(!locked)} />
          <br />
          <br />
          <br />

          Lock is {locked ? "Closed" : "Open"}<br />
          <button
            style={{
              background: locked ? "hsl(340deg 100% 32%)" : "hsl(100deg 100% 32%)",
            }}
            class="pushable"
            id="submit"
            onClick={() => toggleLockState(!locked)}
          >
            <span
              style={{
                backgroundColor: locked ? "#cc264a" : "#0F0",
                color: locked ? "#FFF" : "#000",
              }}
              class="front"
            >
              {locked ? "Press to Unlock" : "Press to Lock"}
            </span>
          </button>


          <div>
            <h6>{addy != 0 ? "Logs for user: " + addy : ""

            }<br/><br/>
              {addy != 0 && typeof logs != "undefined" && Array.from({ length: logs.length }, (value, index) => (


                <>{logs[index].created_at} - {logs[index].logdesc}<br />
                </>
              ))
              }
            </h6>

          </div>

        </center>

      </div>
    </div>
  );
}

export default App;

export { GET_LOGS };
